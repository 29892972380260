import React from 'react';
import {
  Box,
  Flex,
  Text,
  Avatar,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

const Testimonial = ({ text, author }) => {
  return (
    <Box
      p={4}
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="lg"
      mb={4}
      textAlign="center"
    >
      <Text fontSize="lg" mb={4}>
        "{text}"
      </Text>
      <Flex align="center" justify="center">
        <Avatar
          size="sm"
          name={author}
          src="/profile.png"
          mr={2}
        />
        <Text>{author}</Text>
      </Flex>
    </Box>
  );
};

const MotionBox = motion(Box);

const Testimonials = () => {
  const testimonialsData = [
    {
      text: "Working with digitaltek was an absolute pleasure. They delivered beyond our expectations.",
      author: "Happy Client ",
      
    },
    {
      text: "The team at digitaltek is highly skilled and dedicated. They transformed our vision into reality.",
      author: "Satisfied Customer ",
    },
    {
      text: "Digitaltek exceeded our expectations in terms of creativity and timely delivery. Highly recommended!",
      author: "Impressed Client",
    },
    // Add more testimonials as needed
  ];

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      p={8}
    >
      {/* <Text fontSize="2xl" fontWeight="bold" mb={8}>
        Client Testimonials
      </Text> */}
      <Flex
        direction={{ base: 'column', md: 'row' }}
        align="center"
        justify="space-around"
        wrap="wrap"
        gap={2}
      >
        {testimonialsData.map((testimonial, index) => (
          <MotionBox
            key={index}
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
            maxW={{ base: 'full', md: 'sm' }}
          > 
          
            <Testimonial {...testimonial} />
           
          </MotionBox>
        ))}
      </Flex>
    </Flex>
  );
};

export default Testimonials;
