import { Box, Heading, Button, Text, Stack,   FormLabel, Flex, } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; 
import Loading from '../utils/LoadingAnimation';
import { motion } from 'framer-motion';
import { FaEnvelope } from 'react-icons/fa';
import axios from 'axios';



const Services = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [fiverGigs, setFiverGigs] = useState([])
  const MotionBox = motion(Box);


  useEffect(()=>{
    const fetchData = async ()=>{
      try {
       const gigs = await axios.get('https://api.digitaltek.co.in/api/fiver/gigs/list/')
       setFiverGigs(gigs.data)
       
       if(gigs.data){
        setIsLoading(false)
       }
       
      } catch (error) {
        console.log(`error while fetching gigs ${error.message} ${error.status}`)
        setIsLoading(false)
      }
    }
    fetchData()
},[])
  // const services = [
  //   {
  //     title: 'E-commerce Websites',
  //     link: 'https://ecommerce.digitaltek.co.in/',
  //     name:'Ecommerce',
  //   },
  //   {
  //     title: 'Portfolio Websites',
  //     link: 'https://portfolio.digitaltek.co.in/',
  //     name:'Portfolio',
  //   },
  //   {
  //     title: 'Inventory Management System Websites',
  //     link: 'https://inventory-management.digitaltek.co.in/signin/',
  //     name:'Inventory App',
      
  //   },
  //   {
  //     title: 'Hardware Product Listing',
  //     link: 'https://rajengg.co.in/',
  //     name:'Product Listing',
  //   },
  //   {
  //     title: 'Invoice Builder',
  //     link: 'https://invoice-builder.digitaltek.co.in/',
  //     name:'Invoice Builder',
  //   },
  // ];

  
 
  
  return (
    isLoading? <Loading/> :
   <>  
{/* <Box py={10}>
      <Heading as="h2" size="xl" textAlign="center" mb={8}>
        Checkout Our Services Demo
      </Heading> 
      <SimpleGrid alignItems={'center'} columns={{ base: 1, md: 3 }} spacing={8} mx="auto" maxW="900px">
        {services.map((service, index) => ( 
          <Box key={index}  p={6} borderRadius="md" boxShadow="md">
             <Heading as="h3" size="lg" mb={4}>
              {service.title}
            </Heading>
             
              <Button as={Link} target='_blank' to={service.link}>{service.name}</Button>
              {/* <a href={`${}`}> </a>  
        
          </Box>
  //       ))}
  //     </SimpleGrid>*/}
       {/* <VStack  ml={5} spacing={4} align="start">
      <HStack justify="space-between" w="full">
        <Heading as="h3" size="lg">
          FAQ
        </Heading>
        
      </HStack>
     
    </VStack>
    </Box> */}
  <Stack padding={2.5} bg={'whiteALpha.250'} spacing={4} direction="row" wrap={'wrap'} justify="center">
  {fiverGigs.map((plan) => (
    <Flex m={2.5}  justifyContent={'center'} direction={'column'} bg={'green.50'}  padding={5} key={plan.id} maxW={{base:'md', lg:'800px', md:'300px'}} minH={'600px'} p={4} borderWidth="1px" borderRadius="lg" shadow="md">
     <MotionBox
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 20 }}
    transition={{ duration: 0.6, ease: 'easeInOut' }}
    textAlign="center"
    p={8}
  > 
  <Text color={'#1a82ff'} fontSize="xl" fontWeight="bold" mb={2}>
        {plan.title}
      </Text>

      <Text fontSize="sm" textAlign={'justify'}  color="blue.500" mb={4}>
      <FormLabel color={'green.600'} fontFamily={'serif'} fontSize={'x-large'} fontWeight={'bold'}>Description: </FormLabel> {plan.description}
      </Text>

      <Heading color={'green.400'} fontFamily={'serif'} textAlign={'justify'} size={'md'} >Delivery Time: </Heading>
      <Text textAlign={'justify'} fontWeight={'bolder'} fontSize="lg" color="gray.600" mb={4}>
       {`${plan.delivery_time} Days`}
      </Text>

      {/* <Box>
        <Badge padding={2.5} borderRadius={'5px'} colorScheme={'blue'} mb={2}>
        Contact-us for price
        </Badge>
      </Box> */}
      
        {/* {plan.features.map((feature, index) => (
          <p key={index}>{feature}</p>
        ))} */}
       <Text fontWeight={'bold'} fontSize={'medium'} color={'blue.300'} fontStyle={'oblique'} fontFamily={'sans-serif'}>Demo Products:</Text> 
      <Button w={{lg:'35%', md:'100%'}} _hover={{bg:'green.600'}} color={'white'} as={Link} boxShadow='md' bg='green.400' target='_blank' to={plan.gig_url}>{plan.title}</Button>  
     </MotionBox> 
     <Button mx={'auto'}  mb={2.5}  w={{lg:'35%', md:'100%'}} _hover={{bg:'blue.600'}} gap={2} color={'white'} as={Link} boxShadow='md' bg='blue.400'  to={'/contact-us/'}> <FaEnvelope/>  Contact-us for price</Button>
     
    </Flex>
  ))}
 
</Stack>

    </>
  );
};

export default Services;
