import React, { useState, useEffect } from 'react';
import {
  // Container,
  Flex,
  Box,
  Heading,
  Text,
  // IconButton,
  Button,
  VStack,
  HStack,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
  Textarea,
} from '@chakra-ui/react'
import {
  // MdPhone,
  // MdEmail,
  // MdLocationOn,
  // MdFacebook,
  MdOutlineEmail,
} from 'react-icons/md'
import { BsPerson } from 'react-icons/bs'
import { FaPhone, FaWhatsapp  } from "react-icons/fa6";

import Loading from '../utils/LoadingAnimation';
// import { FaEnvelope, FaInstagram, FaLinkedin  } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';

// FaTwitter, FaFacebook, FaWhatsapp, 

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile_no: '',
    inquiry: '',
  });
  const [isLoading, setIsLoading] = useState(true)
  // const [successAlert, setSuccessAlert] = useState(false);
  const toast = useToast()

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
    const response =  await axios.post('https://api.digitaltek.co.in/api/contact-us/send/', formData);
      // setSuccessAlert(true);
      setFormData({
        name: '',
        email: '',
        mobile_no: '',
        inquiry: '',
      });

     
if(response.data){
   toast({
        title: `Message sent successfully`,
        status: 'success',
        duration: 9000,
        isClosable: true,
        position:'top-right'
      })
}
     
      // setTimeout(() => {
      //   setSuccessAlert(false);
      // }, 5000); // Hide the alert after 5 seconds (adjust as needed)
    } catch (error) {
      // console.log(`error while submit contact form: ${error}`);
      // console.error(error)
      setIsLoading(false)
      toast({
        title: `${error.response.data.mobile_no}`,
        status: 'warning',
        duration: 9000,
        isClosable: true,
        position:'top-right'
      })
    }
  };
  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(loadingTimeout);
  }, []);
 ;
 

 
  return (
 
    isLoading? <Loading />: 
   
      <Flex p={5} justifyContent={'center'} >
        <Box
          bg="blue.600"
          color="white"
          borderRadius="lg"
          m={{ sm: 4, md: 16, lg: 10 }}
          p={{ sm: 5, md: 5, lg: 16 }}>
          <Box  p={4}>
            <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
              <WrapItem>
                <Box>
                  <Heading>Contact</Heading>
                    <Text mt={{ sm: 3, md: 3, lg: 5 }} color="whiteAlpha.700">
                    Fill up the form below to contact
                    
                  </Text>
                  <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                    <VStack pl={0} spacing={3} alignItems="flex-start">
                    
                   <Link
                        color="white"
                        to={'mailto:support@digitaltek.co.in'}
                        >
                      <Text fontSize={{lg:'2xl', md:'xl'}}>support@digitaltek.co.in</Text>  
                      </Link>
                     
                      {/* <Button
                        size="md"
                        height="48px"
                        width="200px"
                        variant="ghost"
                        color="white"
                        _hover={{ border: '2px solid white' }}
                        // leftIcon={<MdLocationOn  size="20px" />}
                        >
                        
                      </Button>  */}
                    </VStack>
                  </Box>
                  <HStack
                    mt={{ lg: 10, md: 10 }}
                    spacing={5}
                    px={5}
                    alignItems="flex-start">
                      <Button
                        size="md"
                        height="48px"
                        width="200px"
                        variant="ghost"
                        color="white"
                        _hover={{ filter:'drop-shadow(2px 2px 5px green)' }}
                        leftIcon={<FaWhatsapp   size="50px" />}
                        as={Link}
                        rel={'noopener noreferrer'}
                        target='_blank'
                        to={'https://wa.me/919687109359'}
                        >
                        {/* +91-988888888 */}
                      </Button>
                    {/* <IconButton
                      aria-label="facebook"
                      variant="ghost"
                      size="lg"
                      isRound={true}
                      _hover={{ bg: '#0D74FF' }}
                      icon={<MdFacebook size="28px" />}
                    /> */}
                    {/* <IconButton
                      aria-label="github"
                      variant="ghost"
                      size="lg"
                      isRound={true}
                      _hover={{ bg: '#0D74FF' }}
                      icon={<BsGithub size="28px" />}
                    /> */}
                    {/* <IconButton
                      aria-label="discord"
                      variant="ghost"
                      size="lg"
                      isRound={true}
                      _hover={{ bg: '#0D74FF' }}
                      icon={<BsDiscord size="28px" />}
                    /> */}
                  </HStack>
                </Box>
              </WrapItem>
              <WrapItem>
                <Box bg="white" borderRadius="lg">
                  <Box m={8} color="#0B0E3F">
                    <VStack spacing={5}>
                      <FormControl id="name">
                        <FormLabel>Your Name</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <InputLeftElement pointerEvents="none">
                            <BsPerson color="gray.800" />
                          </InputLeftElement>
                          <Input type="text" name='name' value={formData.name} onChange={handleChange} size="md" />
                        </InputGroup>
                      </FormControl>
                      <FormControl id="name">
                        <FormLabel>Mobile Number</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <InputLeftElement pointerEvents="none">
                          <FaPhone />
                          </InputLeftElement>
                          <Input type="text" name='mobile_no' value={formData.mobile_no} onChange={handleChange} size="md" />
                        </InputGroup>
                      </FormControl>
                      <FormControl id="name">
                        <FormLabel>Mail</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <InputLeftElement pointerEvents="none">
                            <MdOutlineEmail color="gray.800" />
                          </InputLeftElement>
                          <Input value={formData.email} name='email' onChange={handleChange} type="text" size="md" />
                        </InputGroup>
                      </FormControl>
                      <FormControl id="name">
                        <FormLabel>Message</FormLabel>
                        <Textarea
                        name='inquiry'
                        value={formData.inquiry} 
                         onChange={handleChange}
                          borderColor="gray.300"
                          _hover={{
                            borderRadius: 'gray.300',
                          }}
                          placeholder="message"
                          
                        />
                      </FormControl>
                      <FormControl id="name" float="right">
                        <Button variant="solid" bg="#0D74FF" color="white" _hover={{}}  onClick={handleSubmit}>
                          Send Message
                        </Button>
                      </FormControl>
                    </VStack>
                  </Box>
                </Box>
              </WrapItem>
            </Wrap>
          </Box>
        </Box>
      </Flex>
  

  
  );
};

export default ContactForm;