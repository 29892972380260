import { Box, Heading, Image, Text, Button, StackDivider, Stack, Card, CardHeader, CardBody} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import axiosInstance from '../utils/axiosInstance';
import Loading from '../utils/LoadingAnimation';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Pricing from './Pricing';
import { Link } from 'react-router-dom';

import { motion } from 'framer-motion';
import Testimonials from './Testimonial';

// ...



const HomePage = () => {
  
  const MotionBox = motion(Box);
  const [projectDetails, setProjectDetails] = useState([])
  const [projectImages, setProjectImages] = useState([])
  const [isLoading, setIsLoading] = useState(true)


  useEffect(() => {
    const fetchData = async () => {
      try {
        const  projects = await axiosInstance('list/project/');

        // Assuming project_id is needed for fetching images
        const projectIds = projects.data.map(project => project.id);

        const imagesPromises = projectIds.map(id =>
          axiosInstance(`list/project/image/${id}/`)   );
        const imagesData = await Promise.all(imagesPromises);

        // video projects
        // const videoPromises = projectIds.map(id =>
        //   axiosInstance(`list/project/video/${id}/`) );
        // const videoData = await Promise.all(videoPromises);
 
        setProjectImages(imagesData);
        setProjectDetails(projects.data);

        if(projects){
          setIsLoading(false)
        }
      } catch (error) {
        console.log(error.message);
        setIsLoading(false)
      }
    };

    fetchData();


  }, []);

  return (
    isLoading? <Loading/> :
    <MotionBox
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 20 }}
    transition={{ duration: 0.6, ease: 'easeInOut' }}
    textAlign="center"
    p={8}
  >
    <Heading fontFamily={'cursive'}  color={'blue.700'}  fontStyle={'oblique'}  as="h1" size={{base:'md', lg:"2xl", md:'xl'}} mb={4}>
        Your Web Development Solutions
      </Heading>
      <Text fontSize={{lg:"xl", md:'lg'}} mb={6}>
        Crafting bespoke websites for your unique needs.
      </Text>
      <Button colorScheme="blue" as={Link} to={'/services/'} size="lg" mb={8}>
        Explore Our Services
       
      </Button>
      <Pricing/>
<Heading fontFamily={'monospace'} color={'blackAlpha.600'} size={'xl'} mb={2}>
        Our Products
      </Heading>
    <Box display="flex" alignItems={'center'}   flexWrap={'wrap'} gap={5} justifyContent="center">
      
      {projectDetails.map((project, index) => (
        <MotionBox
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.6, ease: 'easeInOut', delay: index * 0.1 }}
          // maxW={{ base: 'md', lg: '800px', md: '400px' }}
          key={project.id}
        >
         <Card maxW={{base:'md', lg:'800px', md:'400px'}} key={project.id} >
  <CardHeader>
    <Heading style={{bg:'white'? {color:'blackAlpha.700'}:{color:'whiteAlpha.400'}}} fontFamily={'serif'}  size='md'>{project.title}</Heading>
  </CardHeader>

  <CardBody>
    <Stack divider={<StackDivider />} spacing='4'>
     <Box>
        <Heading size='xs' textTransform='uppercase'>
          
        </Heading>

        <Box borderRadius={'5px'} mx={'auto'}  >
{projectImages[index] && (
              <Carousel 
              showArrows={false}
    showStatus={false}
    showThumbs={false}
    emulateTouch={true}
    interval={5000}
    stopOnHover={true}
    transitionTime={1000}
              
              key={index}>
                {projectImages[index].data.map((imageData, imgIndex) => (
                  <Box mt={5} mx={'auto'}  key={imgIndex}>
                    <Image objectFit={'contain'} h={{base:'lg', lg:'600px', md:'300px'}}  src={imageData.image} alt={`${project.title} ${imgIndex}`} />
                  </Box>
                ))}
              </Carousel>
            )}
            </Box>

        <Text pt='2' textAlign={'justify'} fontSize='sm'>
         {project.description}
        </Text>
      </Box> 
   
      
    </Stack>
  </CardBody>
</Card>
        </MotionBox>
      ))}
    </Box>

    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.6, ease: 'easeInOut' }}
      mb={12}
    >
     <Box mb={12}>
        {/* Testimonials section */}
        <Heading mt={4} as="h2" size="xl" mb={4}>
          Client Testimonials
        </Heading>
        {/* Testimonial cards */}
        {/* Example: */}
        {/* <Box textAlign="left">
          <Text fontSize="lg" fontStyle="italic" mb={2}>
            "Working with digitaltek was an absolute pleasure. They delivered beyond our expectations."
          </Text>
          <Text fontSize="md" fontWeight="bold">
            - Happy Client
          </Text>
        </Box> */}
        <Testimonials/>
      </Box>
    </MotionBox>

    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.6, ease: 'easeInOut' }}
      mb={12}
    >
      <Box mb={12}>
        {/* Call-to-action section */}
        <Heading as="h2" size="xl" mb={4}>
          Ready to Get Started?
        </Heading>
        <Text fontSize="lg" mb={6}>
          Let's discuss your project needs and create something extraordinary.
        </Text>
        <Button as={Link} to={'/contact-us/'} colorScheme="green" size="lg">
          Contact Us
        </Button>
      </Box>
    </MotionBox>
  </MotionBox>
  );
};

export default HomePage;
