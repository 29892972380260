import axios from 'axios';

const baseURL = 'https://api.portfolio.digitaltek.co.in/api/';

const axiosInstance = axios.create({
  baseURL: baseURL,
  // timeout:5000,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

export default axiosInstance;