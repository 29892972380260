import { Box, Heading, SimpleGrid, Text,Button, Stack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const Pricing = () => {
  const plans = [
    {
      title: 'Basic',
      price: 'For Price',
      features: ['Static websites', 'Minimal features', 'Templates'],
      to:'/contact-us/'
    },
    {
      title: 'Standard',
      price: 'For Price',
      features: ['Dynamic sites', 'E-commerce', 'Specific functionality'],
      to:'/contact-us/'
    },
    {
      title: 'Premium',
      price: 'For Price',
      features: ['Fully customized websites', 'Advanced features', 'Personalized support'],
      to:'/contact-us/'
    },
  ];

  return (
    <Box py={10}>
      <Heading as="h2" size="xl" color={'teal.700'} fontFamily={'sans-serif'} textAlign="center" mb={8}>
        Our Pricing
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} mx="auto" maxW="900px">
        {plans.map((plan, index) => (
          <Box key={index}  p={6} borderRadius="md" boxShadow="md">
            <Heading as="h3" size="lg" mb={4}>
              {plan.title}
            </Heading>
            <Text fontSize="2xl" fontWeight="bold" color={'gray.400'}  mb={4}>
              {plan.price}
            </Text>
            <Button to={plan.to} as={Link} bg={'teal.400'} color={'white'} _hover={{bg:'teal.600'}}>Contact-us</Button>
            <Stack spacing={2}>
              {plan.features.map((feature, idx) => (
                <Text key={idx} fontSize="md">
                  - {feature}
                </Text>
              ))}
            </Stack>
            
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default Pricing;
