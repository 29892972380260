import Home from "./components/Homepage";
import Header from "./Header";
import Footer from "./Footer";
import {  BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Box } from '@chakra-ui/react';
import Services from "./components/Services";
import ContactForm from "./components/ContactUs";
import AboutUs from "./components/AboutUs";
import Blog from "./components/Blog";

function App() {
  return (
    <>
      <Router>
       
        <Header/>  
      <Box minH={'100vh'}>  
         <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/services/" element={<Services/>}/>
          <Route path="/contact-us/" element={<ContactForm/>}/>
          <Route path="/about/" element={<AboutUs/>}/>
          <Route path="/blog/" element={<Blog/>}/>
         
        </Routes></Box>
        <Footer/>
      </Router>
    </>
  );
}

export default App;
