import React from 'react';
// import './css/style.css'
import { Spinner , Container,  } from '@chakra-ui/react';
// import { motion } from 'framer-motion';

const Loading = () => {
//   const animationKeyframes = keyframes`
//   0% { transform: scale(1) rotate(0); border-radius: 20%; }
//   25% { transform: scale(2) rotate(0); border-radius: 20%; }
//   50% { transform: scale(2) rotate(270deg); border-radius: 50%; }
//   75% { transform: scale(1) rotate(270deg); border-radius: 50%; }
//   100% { transform: scale(1) rotate(0); border-radius: 20%; }
// `;

// const animation = `${animationKeyframes} 2s ease-in-out infinite`;
  return (
    <Container  mx={'auto'} h="100vh" display="flex" alignItems={'center'} justifyContent="center"> 
    <Spinner   thickness='4px'
  speed='0.65s'
  emptyColor='gray.200'
  color='blue.500'
  size='xl' />
      {/* <Flex  as={motion.div} animation={animation} justifyContent={'center'} alignItems={'center'} w={'50px'} h={'50px'} borderRadius={'50%'} border={'2px'} borderColor={'#accbee'}>
         <Box
     
      
      // not work: transition={{ ... }}
      padding="2"
      // @ts-ignore - "Does not exist" Type Error against Motion
      bgGradient="linear-gradient(to top, #c1dfc4 0%, #deecdd 100%)"
      width="8"
      height="8"
      display="flex"
    />
      </Flex> */}
   
  </Container>
  );
};

export default Loading;