import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  IconButton,
  Avatar,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { FaGlobe, FaCode, FaUsers, FaRocket, FaEnvelope } from 'react-icons/fa';
import Loading from '../utils/LoadingAnimation';
import axiosInstance from '../utils/axiosInstance';
import { Link } from 'react-router-dom';




const AboutUs = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [profileData, setProfileData] = useState([])
  
  useEffect(() => {
    const getProfile = async ()=>{
      const profile = await axiosInstance('https://api.portfolio.digitaltek.co.in/api/list/about/')
      setProfileData(profile.data)

    }
    getProfile()

    const timeoutId = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);
  return (
    isLoading? <Loading/>:
    <Flex
      wrap={'wrap'}
      justifyContent="center"
      alignItems={'center'}
      direction="column"
     
    >
      <MotionBox
       initial={{ opacity: 0, y: 20 }}
       animate={{ opacity: 1, y: 0 }}
       exit={{ opacity: 0, y: 20 }}
       transition={{ duration: 0.6, ease: 'easeInOut' }}
       textAlign="center"
       p={8}
        mt={8}
      >
        <Box
          // bg="white"
          p={4}
          rounded="md"
          textAlign="center"
          
        >
          <Text fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }} fontWeight="bold" mb={4}>
            Welcome to Digitaltek
          </Text>
          <Text fontSize={{ base: "lg", md: "xl", lg: "2xl" }} mb={6}>
            🌐 Building the Future of the Web 🚀
          </Text>
          <Text textAlign={'justify'} fontSize={{ base: "sm", md: "md", lg: "lg" }} mb={4}>
            At Digitaltek, we are more than just developers - we are innovators. Our team is passionate about crafting unique and powerful web experiences that propel businesses to new heights. With a commitment to excellence and creativity, we bring your ideas to life in the digital world.
          </Text>
          <Flex mt={4} flexWrap="wrap"  justify="center">
          <Feature icon={<FaGlobe />} title="Global Presence">
        We serve clients around the world, bringing a global perspective to every project.
      </Feature>
      <Feature icon={<FaCode />} title="Cutting-Edge Technology">
        Embracing the latest technologies to deliver solutions that stand the test of time.
      </Feature>
      <Feature icon={<FaUsers />} title="Collaborative Team">
        Our collaborative approach ensures that your project benefits from diverse expertise.
      </Feature>
      <Feature icon={<FaRocket />} title="Results-Driven">
        Focused on delivering results that propel your business forward with unmatched speed.
      </Feature>
          </Flex>
          
        </Box>
         
        <Box> 
       {profileData.map(about=>(
         <Flex key={about.id} mb={4} direction="column" align="center">
         <Avatar size={'2xl'} name={about.name}  src={about.profile_picture} />
         <Heading color={'cyan.500'} as="h3" size="lg" mb={4}>
           {about.name}
         </Heading>
         <Text fontSize="lg" mb={4}>
           Full Stack Developer
         </Text>
         {/* About Me Section */}

         <Box w={{ base: '300px', lg: 'lg' }}>
           <Text color={'gray.400'} textAlign={'justify'}>
             {about.bio}
           </Text>
         </Box>
         </Flex>
      ))}
       </Box>

        <Flex mt={5} mb={4} justify={'center'}>
          <Accordion textAlign={'justify'} w={{ base: 'full', lg: 'lg' }} defaultIndex={[0]} allowToggle>
            <Text fontSize={'lg'} textAlign={'center'}>
              FAQ
            </Text>
            <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="justify">
         1.    What technologies do you specialize in for backend development, and how do you ensure the efficiency and performance of websites?
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Text>As a Full Stack Developer, I specialize in backend development using technologies such as Django, Django REST Framework, and DRF Simple JWT. These frameworks allow me to build robust and efficient web applications. I prioritize optimizing database queries, utilizing caching mechanisms, and implementing best practices to ensure the websites I develop are not only feature-rich but also performant.</Text>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="justify">
          2.   Can you provide insights into your frontend development expertise, particularly with ReactJS and Chakra UI?
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Text>Absolutely. I have extensive experience in frontend development, particularly with ReactJS and Chakra UI. ReactJS allows me to create dynamic and responsive user interfaces, ensuring a seamless user experience. Chakra UI, with its component library and design system, enables me to build visually appealing and consistent UIs efficiently. Combining these technologies, I focus on crafting user-friendly interfaces that enhance the overall usability of websites.</Text>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="justify">
       3.   How do you approach project delivery, and what sets your portfolio apart in terms of timely project completion?
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Text>My approach to project delivery revolves around a commitment to excellence and efficiency. I follow Agile methodologies to break down projects into manageable tasks, ensuring a steady and prompt development process. Additionally, my proficiency in Django and ReactJS allows me to work seamlessly on both frontend and backend aspects, reducing development time. The track record in my portfolio showcases my dedication to delivering top-notch projects promptly, meeting and exceeding client expectations.</Text>
          </AccordionPanel>
        </AccordionItem>
          </Accordion>
        </Flex>
      </MotionBox>

      <Flex mb={3}>
          <Text fontSize={{ base: "md", md: "lg", lg: "xl" }}  mt={6}>
            Ready to take your online presence to the next level? Contact us today for a consultation!
            <Button ml={3}  _hover={{bg:'blue.600'}} gap={2} color={'white'} as={Link} boxShadow='md' bg='blue.400'  to={'/contact-us/'}> <FaEnvelope/> Contact-us</Button>
          </Text>
      </Flex>
    
    </Flex>
  );
};

const MotionBox = motion(Box);

const Feature = ({ icon, title, children }) => (
  <Box textAlign="left" mb={4} mx={2}>
    <IconButton icon={icon} boxSize={8} color="teal.500" mb={2} />
    <Text fontWeight="bold" fontSize="md" mb={2}>{title}</Text>
    <Text fontSize="md">{children}</Text>
  </Box>
);

export default AboutUs;
