import {  Flex,  Image, Link,  Collapse, Stack, useColorMode, Button } from '@chakra-ui/react';
import {  SunIcon, MoonIcon } from '@chakra-ui/icons';
import { useState } from 'react';



const Header = () => {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  // const { colorMode, toggleColorMode } = useColorMode();
  const [isOpen, setIsOpen] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode()

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Flex
    as="nav"
    align="center"
    justify="space-between"
    wrap="wrap"
    w="100%"
    p={4}
    bg="blue.500"
    color="white"
  >
    <Flex align="center" mr={5}>
     <Link href='/'> <Image h='50px' w='50px' objectFit={'contain'} src='/logo.png'/> </Link>
      <Button  ml={5} bg={'transparent'} _hover={{bg:'transparent'}} gap={1}  fontSize={'14px'}  onClick={toggleColorMode}>
         {colorMode === 'light' ? <> <MoonIcon fontSize="20px" color={'gray.400'} /> </>: <> <SunIcon fontSize="20px"  color={'orange.400'}/></> }
      </Button>
    </Flex>

    <Stack
      direction={{ base: 'column', md: 'row' }}
      display={{ base: 'none', md: 'flex' }}
      width={{ base: 'full', md: 'auto' }}
      mt={{ base: 4, md: 0 }}
      spacing={4}
    >
      
      {/* Your navigation links */}
      <Link href="/">Home</Link>
      <Link href="/services/">Services</Link>
      {/* <Link href="/portfolio/">Portfolio</Link> */}
      <Link href="/about/">About</Link>
      <Link href="/contact-us/">Contact</Link>
    </Stack>

    {/* Mobile menu button */}
    <Flex
      display={{ base: 'flex', md: 'none' }}
      onClick={toggleMenu}
      cursor="pointer"
    >
      <svg fill="white" width="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0h24v24H0z" fill="none"/>
        <path d="M4 6h16M4 12h16m-7 6h7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </Flex>

    {/* Collapsible menu for small screens */}
    <Collapse in={isOpen} animateOpacity>
      <Flex
        direction="column"
        align="start"
        bg="gray.600"
        w="100%"
        mt={4}
        p={2}
        color="white"
      >
        {/* Your navigation links */}
        <Link href="/">Home</Link>
        <Link href="/services/">Services</Link>
        {/* <Link href="/portfolio/">Portfolio</Link> */}
        <Link href="/about/">About</Link>
        <Link href="/contact-us/">Contact</Link>
      </Flex>
    </Collapse>
  </Flex>
  );
};

// const Footer = () => {
//   return (
//     <Box bg="gray.200" py={6} px={4} textAlign="center">
//       <Text>&copy; 2024 Your Business Name. All rights reserved.</Text>
//     </Box>
//   );
// };

export default Header
