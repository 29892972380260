import React from 'react'
import BlogComponent from './SingleBlog'



const Blog = () => {
  return (
    <div>
      <BlogComponent
        title="Example Blog Post"
        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eget urna ut elit commodo convallis. Duis vehicula, urna quis vestibulum pharetra, lorem odio dictum eros, ac viverra lacus quam nec leo."
        imageUrl="https://example.com/image.jpg"
        // videoUrl="https://www.youtube.com/embed/your-video-id"
      />
    </div>
  )
}

export default Blog