import React, { useEffect, useState } from "react";
import { Box,  Flex, Heading, Text } from "@chakra-ui/react";
import axiosInstance from "../utils/axiosInstance";

const BlogComponent = ({ title, content, imageUrl, videoUrl }) => {
    const [blogData, setBlogData] = useState([])
    useEffect(()=>{
        const fetchdata = async ()=>{
            try {
                const response = await axiosInstance('list/blog/posts/')
                setBlogData(response.data) 
            } catch (error) {
                console.log(error)
            }
        }
        fetchdata()
    },[])

    console.log(blogData)
  return (
    <article>
        {blogData.map(blog=>(
             <Flex
        direction={{ base: "column", md: "row" }}
        maxW="800px"
        mx="auto"
        p={4}
        boxShadow="md"
        borderRadius="md"
        bg="white"
      >
        <Box flex="1">
          
            {/* <Image src={} alt="Blog Image" w="100%" h="auto" />
       
     
            <Box>
              <iframe
                src={}
                title="Blog Video"
                width="100%"
                height="auto"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </Box>
       */}
        </Box>
        <Box flex="1" ml={{ base: 0, md: 4 }}>
          <Heading as="h2" size="lg" mb={4}>
            {blog.caption}
          </Heading>
          <Text>{blog.description}</Text>
        </Box>
      </Flex>
        ))}
     
    </article>
  );
};

export default BlogComponent;
