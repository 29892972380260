import { Box, Flex, Text, Link, Stack } from '@chakra-ui/react';
import { FaTwitter, FaFacebook, FaWhatsapp, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <Box bg={'blue.700'} color="white" py={6}>
      <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" alignItems="center" px={4}>
        {/* Logo or Site Name */}
        <Text fontSize="xl" fontWeight="bold" mb={{ base: 4, md: 0 }}>
          Digitaltek
        </Text>

        {/* Navigation Links */}
        <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: 2, md: 4 }} mb={{ base: 4, md: 0 }}>
          <Link href="/">Home</Link>
          <Link href="/services/">Services</Link>
          {/* <Link href="/portfolio/">Portfolio</Link> */}
          <Link href="/about/">About</Link>
          <Link href="/contact-us/">Contact</Link>
        </Stack>

        {/* Social Media Icons */}
        <Stack direction="row" spacing={4}>
          <Link target='_blank'rel="noreferrer" href="#" aria-label="Twitter">
            <FaTwitter/>
          </Link>
          <Link target='_blank' rel="noreferrer" href="#" aria-label="Facebook">
            <FaFacebook/>
          </Link>
          <Link target='_blank'  rel="noreferrer" href="https://www.linkedin.com/in/hakimuddin-sohangpur-3aa439177/" aria-label="LinkedIn">
            <FaLinkedin/>
          </Link>
          <Link target='_blank' rel="noreferrer" href="#" aria-label="Whatsapp">
            <FaWhatsapp/>
          </Link>
          <Link target='_blank' rel="noreferrer"  href="https://www.instagram.com/digitaltek_786110/" aria-label="Instagram">
            <FaInstagram/>
          </Link>
        </Stack>
      </Flex>

      {/* Footer Bottom */}
      <Box textAlign="center" mt={6}>
        <Text>&copy; {new Date().getFullYear()} Digitaltek. All rights reserved.</Text>
      </Box>
    </Box>
  );
};

export default Footer;
